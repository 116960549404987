@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root{
    --color-bg: rgba(218, 218, 218, 0.749);
    --color-bg-variant: rgba(211, 211, 211, 0.7);
    --color-primary: #3498DB;
    --color-primary-variant: #21618C;
    --color-white: #fff;
    --color-light: rgba (255, 255, 255, .6);
    --color-dark: #262626;
    --color-success: #00B74A;

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body{
    font-family: 'Poppins', sans-serif;
    color: var(--color-white);
    line-height: 1.7;
}

/*========= GENERAL STYLES =================*/
.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5{
    font-weight: 500;
}

h1{
    font-size: 2.5rem;
}

section{
    padding-top: 4rem;
}

section > h2, section > h5{
    text-align: center;
    color: var(--color-light);
}

section > h5{
    color: var(--color-dark);
}

section > h2{
    font-size: 2rem;
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-light);
}

a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-primary-variant);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: .75rem 1.2rem;
    border-radius: .4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover{
    background: var(--color-primary-variant);
    color: var(--color-primary);
    border-color: var(--color-primary-variant);
}

.btn-primary{
    background: var(--color-primary);
    color: #FFF;
}

.btn-primary-outline{
    border: 3px solid var(--color-primary);
    color: var(--color-white);
}

.btn-succes{
    background: var(--color-success);
    color: #FFF;
    border: 1px solid var(--color-success);
}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/*========= MEDIA QUERIES (MEDIUM) =================*/
@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}

/*========= MEDIA QUERIES (SMALL) =================*/
@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }

    section > h2{
        margin-bottom: 2rem;
    }
}