header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    padding: 0 2rem;
    background-color: var(--color-dark);
    color: var(--color-light);
    position: fixed;
    top: 0;
    z-index: 100;
}

nav a{
    margin: 0 2rem;
    color: var(--color-light);
    text-decoration: none;
}
nav a:hover{
    color: var(--color-white);
}

header div img{
    object-fit: contain;
    border-radius: 0;
    height: 4rem;
}


header .nav__btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--color-light);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}


@media only screen and (max-width: 1024px){
    header .nav__btn {
        visibility: visible;
        opacity: 1;
    }    

    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--color-dark);
        transition: 1s;
        transform: translateY(-100vh);
    }

    header .responsive__nav{
        transform: none;
    }

    nav .nav__close__btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a{
        font-size: 1.5rem;
    }
}




/*
nav{
    background: rgba(0, 0, 0, .3);
    width: 100%;
    display: block;
    z-index: 2;
    position: fixed;
    top: 0;
    display: flex;
    backdrop-filter: blur(15px);
    justify-content: space-around;
}

.nav__menu{
    background: transparent;
    display: flex;
    font-size: 1.1rem;
    align-items: center;
}
.nav__menu a{
    padding: .5rem;
    gap: 2rem;
    color: var(--color-light);

}
.nav__menu a:hover{
    color: var(--color-primary);
    background: var(--color-bg);
}

nav a img{
    object-fit: contain;
    border-radius: 0;
    width: 3rem;
    height: 3.5rem;
    padding: .5rem;
}







    <nav>

      <Link to='/'>
        <div className="brand"><a href="#header" target='_blank' rel="noreferrer"><img src={Logo} alt="LOGO" /></a></div>
      </Link>
      


      <div className="nav__menu">
        

        <Link to='/shop'>
          <a href='#catalogo'>CATALOGO</a>
        </Link>

        <Link to='/aboutus'>
          <a href='#catalogo'>NOSOTROS</a>
        </Link>
        
        <a href="#contact">CONTACTO</a>
      </div>
      
      
    </nav>

*/